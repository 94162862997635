<template>
  <div class="infromation">
    <van-nav-bar 
      title="我的消息" 
      left-arrow 
      @click-left="onClickLeft" />
    <main>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多"
        @load="onLoad"
      >
        <div 
          class="box"
          v-for="(item, index) in boxList"
          :key="index">
          <div class="boxcontent">
            <div class="top fl-between">
              <div class="left fl-between">
                <img class="img" src="../assets/image/xiaoxi.png" alt="">
                <p class="title">系统消息</p>
              </div>
              <p class="time">{{item.createdAt}}</p>
            </div>
            <div class="line"></div>
            <p class="tjr">{{(item.content).slice(0, 6) === '佣金已到账:' ? `佣金已到账:  ${Number((item.content).slice(6)).toFixed(2)}` : item.content }}</p>
          </div>
        </div>
      </van-list>
    </main>
  </div>
</template>

<script>
import { noticeList } from '@/api'
export default {
  data() {
    return {
      loading: false,
      finished: false,
      boxList: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back(-1);
    },
    getList() {
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      noticeList(data).then(res => {
        this.total = res.data.total;
        if(this.total <= this.pageSize){
          this.boxList = res.data.list;
        }else{
          this.pageNum++;
          let arr = res.data.list;
          this.boxList = this.boxList.concat(arr);
        }
        this.loading = false;
        // 数据全部加载完成
        if (this.boxList.length >= this.total) {
          this.finished = true;//结束，显示我也是有底线的
        }
      })
    },
    onLoad() {
      this.getList()
    }
  },
};
</script>

<style scoped lang="scss">
.infromation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  main{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    .box{
      width: 351px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(160, 160, 160, 0.4);
      border-radius: 4px;
      margin-top: 13px;
      padding-bottom: 13px;
      .boxcontent{
        width: 320px;
        margin: auto;
        padding-top: 13px;
        .top{
          .left{
            width: 106px;
            padding-left: 6px;
            .img{
              width: 24px;
              height: 24px;
            }
            .title{
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 22px;
            }
          }
          .time{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(79, 79, 79, 0.58);
            line-height: 17px;
          }
        }
        .line{
          width: 320px;
          height: 1px;
          margin-top: 9px;
          border-bottom: 1px solid #F3F3F3;
        }
        .tjr{
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #6E7278;
          line-height: 20px;
          padding-top: 12px;
          margin: auto;
        }
      }
    }
  }
}
</style>
